<template>

    <section id="main">
     
      <video src="https://res.cloudinary.com/oscarjite/video/upload/v1662154978/walking_on_the_moon_j5tytl.mp4" muted autoplay loop></video>
      <div class="main-text">
      
        <p>
          This demo shows a video background with no overlay.
        </p>

     
      </div>
    </section>

</template>

<script>
export default {
  data() {
    return {};
  },
  setup() {
    return {};
  },
  components: {},

  beforeMount() {
    
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Asap&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


#main {
  width: 100%;
  height: 100vh;
  position: relative;
}
.main-text{
  position: absolute;
  left: 13%;
  top: 42%;
  transform: translate(-13%,-42%);
  line-height: 0;
}
.main-text p{
  font-size: 20px;
  color: #e0ffff;
  margin: 25px 0px 25px;
  line-height: 50px;
}

#main video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  object-fit: cover;
}
</style>
